import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/css/modal-video.min.css'
import styled from 'styled-components'

import playIcon from '../../assets/media/play.svg'
import groupPhoto from '../../assets/media/team.jpg'

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1800px;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  height: 700px;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        to right,
        transparent,
        white 20%,
        white 80%,
        transparent
      ),
      linear-gradient(to bottom, transparent, white 20%, white 80%, transparent);
    pointer-events: none;
  }
`

const Image = styled.img`
  width: 100%;
  display: block;
  transition: transform 0.5s ease-in-out;
  filter: blur(5px);
  transform: scale(1);

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    filter: blur(0);
  }
`

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 1);
  border-radius: 50%;
  border: none;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
    filter: invert(100%);
  }
`

export const CompanyShowreel: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = (): void => setIsOpen(true)

  return (
    <>
      <Container>
        <ImageWrapper>
          <Image src={groupPhoto} alt='Company group photo' />
        </ImageWrapper>
        <PlayButton onClick={openModal}>
          <img src={playIcon} alt='Play icon' />
        </PlayButton>
      </Container>
      <ModalVideo
        channel='youtube'
        isOpen={isOpen}
        videoId='YOUR_VIDEO_ID'
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}
