import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Logo from '../Logo/Logo'
import { MenuIcon } from '../MenuIcon/MenuIcon'
import { SidebarMenu } from '../SidebarMenu/SidebarMenu'
import { Box, Container, HeaderContainer, Nav } from './Header.styles'

export const Header: React.FC = () => {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = (): void => setIsMenuOpen(!isMenuOpen)

  return (
    <Container>
      <HeaderContainer>
        <Link to='/'>
          <Logo />
        </Link>
        <Box>
          <Nav>
            <ul>
              <li>
                <Link to='/cases'>{t('cases.cases')}</Link>
              </li>
              <li>
                <Link to='/services'>{t('services.services')}</Link>
              </li>
              <li>
                <Link to='/company'>{t('company.company')}</Link>
              </li>
              <li>
                <Link to='/careers'>{t('careers.careers')}</Link>
              </li>
              <li>
                <Link to='/contact'>{t('contacts.contact')}</Link>
              </li>
            </ul>
          </Nav>
          <MenuIcon onClick={toggleMenu} />
        </Box>
      </HeaderContainer>
      <SidebarMenu isOpen={isMenuOpen} onClose={toggleMenu} />
    </Container>
  )
}
