import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CompanyShowreel } from '../CompanyShowreel/CompanyShowreel'

const Container = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 100px;
`

export const CompanyMain: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('company.aboutUs')}</Title>
      <CompanyShowreel />
    </Container>
  )
}
