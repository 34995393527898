import { animated } from 'react-spring'
import styled from 'styled-components'

export const OfferBlockContainer = styled.div`
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  @media (max-width: 768px) {
    height: auto;
    padding: 20px 10px;
    margin-top: 100px;
  }
`

export const Title = styled(animated.h1)`
  font-weight: 600;
  font-size: 80px;
  line-height: 88px;
  color: #000;
  max-width: 85%;
  margin-bottom: 30px;
  text-transform: none;

  @media (max-width: 1200px) {
    font-size: 60px;
    line-height: 68px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    max-width: 100%;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 30px;
    line-height: 38px;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 10px;
    justify-content: center;
  }
`

export const Tag = styled(animated.div)<{ isActive?: boolean; bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  border-radius: 30px;
  width: 150px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ isActive }) => (isActive ? '#fff' : '#000')};
  font-family: 'Gilroy', sans-serif;
  font-weight: 700;
  font-size: 25px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s, width 0.3s, height 0.3s;

  &:nth-child(4n) {
    width: 200px;
  }

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1200px) {
    padding: 15px 25px;
    font-size: 22px;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 18px;
  }

  @media (max-width: 480px) {
    padding: 8px 15px;
    font-size: 16px;
    width: 90px;

    &:nth-child(4n) {
      width: 150px;
    }
  }

  div {
    white-space: nowrap;
  }
`

export const TagIcon = styled.div`
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 5px;
  }
`
