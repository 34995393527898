import React from 'react'
import Slider, { Settings } from 'react-slick'
import styled from 'styled-components'

import profile1 from '../../assets/media/call.jpg'

const Container = styled.div`
  text-align: center;
  margin: 150px 0;

  @media (max-width: 768px) {
    margin: 100px 0;
  }

  @media (max-width: 480px) {
    margin: 50px 0;
  }
`

const SliderSlick = styled(Slider)`
  cursor: grab;

  .slick-list {
    overflow: hidden;
  }

  .slick-slide {
    padding: 0 10px;

    @media (max-width: 768px) {
      padding: 0 5px;
    }
  }
`

const TestimonialCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  margin: 10px;
  max-width: calc(70% - 10px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: calc(90% - 10px);
  }

  @media (max-width: 480px) {
    max-width: calc(100% - 10px);
  }
`

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`

const TestimonialHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const Name = styled.h3`
  font-size: 18px;
  margin: 0;
`

const Position = styled.p`
  font-size: 14px;
  color: #777;
  margin: 0;
`

const TestimonialText = styled.p`
  font-size: 16px;
  color: #555;
  line-height: 1.6;
`

const ArrowButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: #333;
`

const PrevArrow = styled(ArrowButton)`
  left: -40px;

  @media (max-width: 768px) {
    left: -20px;
  }
`

const NextArrow = styled(ArrowButton)`
  right: -40px;

  @media (max-width: 768px) {
    right: -20px;
  }
`

export const TestimonialsSlider: React.FC = () => {
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow>&rarr;</NextArrow>,
    prevArrow: <PrevArrow>&larr;</PrevArrow>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Container>
      <SliderSlick {...settings}>
        <TestimonialCard>
          <TestimonialHeader>
            <ProfileImage src={profile1} alt='Profile 1' />
            <div>
              <Name>Краснолуцкий Игорь</Name>
              <Position>Генеральный директор GMS</Position>
            </div>
          </TestimonialHeader>
          <TestimonialText>
            ООО «Глобал Медикал Систем» выражает благодарность ООО «Эверест» за
            сотрудничество, высокий уровень сервиса и профессионализм.
          </TestimonialText>
        </TestimonialCard>
        <TestimonialCard>
          <TestimonialHeader>
            <ProfileImage src={profile1} alt='Profile 1' />
            <div>
              <Name>Краснолуцкий Игорь</Name>
              <Position>Генеральный директор GMS</Position>
            </div>
          </TestimonialHeader>
          <TestimonialText>
            ООО «Глобал Медикал Систем» выражает благодарность ООО «Эверест» за
            сотрудничество, высокий уровень сервиса и профессионализм.
          </TestimonialText>
        </TestimonialCard>
        <TestimonialCard>
          <TestimonialHeader>
            <ProfileImage src={profile1} alt='Profile 1' />
            <div>
              <Name>Краснолуцкий Игорь</Name>
              <Position>Генеральный директор GMS</Position>
            </div>
          </TestimonialHeader>
          <TestimonialText>
            ООО «Глобал Медикал Систем» выражает благодарность ООО «Эверест» за
            сотрудничество, высокий уровень сервиса и профессионализм.
          </TestimonialText>
        </TestimonialCard>
        <TestimonialCard>
          <TestimonialHeader>
            <ProfileImage src={profile1} alt='Profile 2' />
            <div>
              <Name>Васильев Александр</Name>
              <Position>Генеральный директор ООО «ЛАНТА»</Position>
            </div>
          </TestimonialHeader>
          <TestimonialText>
            Сотрудникам студии не нужно было вживаться в роль пользователей
            нашего будущего приложения – их сотрудники, каждый у себя дома, уже
            давно были нашими клиентами.
          </TestimonialText>
        </TestimonialCard>
        {/* Add more testimonial cards as needed */}
      </SliderSlick>
    </Container>
  )
}
