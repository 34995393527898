import React from 'react'
import { useTranslation } from 'react-i18next'

import profileImg from '../../assets/media/call.jpg'
import useModal from '../../hooks/useModal'
import { Modal } from '../Modal/Modal'
import { ButtonContainer, ProfileImage } from './DiscussButton.styles'

export const DiscussButton: React.FC = () => {
  const { t } = useTranslation()
  const { isOpen, handleOpen, handleClose } = useModal()

  return (
    <>
      <ButtonContainer onClick={handleOpen}>
        <ProfileImage src={profileImg} alt='Profile' />
        {t('main.discussButton')}
      </ButtonContainer>
      <Modal isOpen={isOpen} onClose={handleClose} />
    </>
  )
}
