import { CareersMain } from '../components/CareersMain/CareersMain'
import { JobBlock } from '../components/JobBlock/JobBlock'

export const Careers: React.FC = () => {
  return (
    <>
      <CareersMain />
      <JobBlock />
    </>
  )
}
