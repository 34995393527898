import React from 'react'
import styled from 'styled-components'

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Logo: React.FC = () => (
  <LogoContainer>
    <svg
      width='150'
      height='60'
      viewBox='0 0 150 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <text
        x='0'
        y='40'
        fontFamily='Gilroy'
        fontSize='40'
        fontWeight='bold'
        fill='#000'
      >
        oralex
      </text>
    </svg>
  </LogoContainer>
)

export default Logo
