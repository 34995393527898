import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 150px 0;
  padding: 20px;
  background-color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 100px 0;
  }

  @media (max-width: 480px) {
    margin: 50px 0;
    padding: 10px;
  }
`

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    align-items: center;
    margin-bottom: 20px;
  }
`

export const Title = styled.h2`
  font-size: 50px;
  line-height: 58px;
  font-weight: 600;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`

export const Subtitle = styled.p`
  font-size: 16px;
  color: #777;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    text-align: center;
  }
`

export const Email = styled.a`
  font-size: 20px;
  color: #000;
  text-decoration: none;
  margin-bottom: 20px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`

export const Button = styled.a`
  display: inline-block;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 500;
  background-color: #7d4cdb;
  color: #fff;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: #6933b9;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 12px 25px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 12px;
  }
`

export const Address = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    align-items: center;
  }
`

export const City = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 18px;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`

export const AddressText = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 15px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`
