import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import new_1 from '../../assets/media/1.png'
import new_2 from '../../assets/media/2.jpg'
import new_3 from '../../assets/media/3.jpg'
import new_4 from '../../assets/media/4.jpg'
import new_5 from '../../assets/media/5.png'
import new_6 from '../../assets/media/6.jpg'

const NewsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1800px;
  margin: 0 auto;
  padding-bottom: 100px;
  padding: 0 10px 100px;
`

const NewsItem = styled.div`
  flex: 1 1 calc(33.333% - 20px);
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    flex: 1 1 calc(50% - 20px);
  }

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`

const NewsImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 200px;
  }

  @media (max-width: 480px) {
    height: 150px;
  }
`

const NewsContent = styled.div`
  padding: 15px;
`

const NewsDate = styled.div`
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
`

const NewsTitle = styled.h3`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`

export const NewsList: React.FC = () => {
  const { t } = useTranslation()

  const newsData = [
    {
      image: new_1,
      date: t('blog.news1.date'),
      title: t('blog.news1.title'),
    },
    {
      image: new_2,
      date: t('blog.news2.date'),
      title: t('blog.news2.title'),
    },
    {
      image: new_3,
      date: t('blog.news3.date'),
      title: t('blog.news3.title'),
    },
    {
      image: new_4,
      date: t('blog.news4.date'),
      title: t('blog.news4.title'),
    },
    {
      image: new_5,
      date: t('blog.news5.date'),
      title: t('blog.news5.title'),
    },
    {
      image: new_6,
      date: t('blog.news6.date'),
      title: t('blog.news6.title'),
    },
  ]

  return (
    <NewsContainer>
      {newsData.map(news => (
        <NewsItem key={news.title}>
          <NewsImage src={news.image} alt={news.title} />
          <NewsContent>
            <NewsDate>{news.date}</NewsDate>
            <NewsTitle>{news.title}</NewsTitle>
          </NewsContent>
        </NewsItem>
      ))}
    </NewsContainer>
  )
}
