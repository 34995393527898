import { Box, DialogTitle, Typography } from '@mui/material'
import { styled } from 'styled-components'

export const Container = styled.div`
  margin: 100px 0;

  .MuiDialogTitle-root {
    padding: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
`

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const InlineFieldsContainer = styled(Box)`
  display: flex;
  gap: 20px;
`

export const FileInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`

export const Title = styled.h2`
  font-size: 50px;
  font-weight: 700;
`

export const CustomDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CustomDialogText = styled.p`
  color: #666;
  margin-bottom: 20px;
  padding: 20px 0;
`

export const AcceptanceText = styled(Typography)`
  font-size: 14px;
  color: #666;
  margin-left: 20px;
  width: 400px;
  a {
    color: #6c63ff;
  }
`

export const StyledButton = styled.div`
  background-color: #6c63ff;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 15px;
  &:hover {
    cursor: pointer;
    background-color: #5b52e6;
  }
`

export const Input = styled.input`
  display: none;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 30px;
    margin-right: 8px;
  }
`

export const AttachText = styled.p`
  font-weight: 600;
  font-size: 20px;
`

export const SubmitSection = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  width: 70%;
`
