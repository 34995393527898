import React from 'react'
import { useTranslation } from 'react-i18next'

import downloadIcon from '../../assets/media/download.svg'
import pdf from '../../assets/utils/intro.pdf'
import {
  Box,
  CallToActionButton,
  CallToActionContainer,
  CallToActionText,
  FormatDescription,
  FormatExampleDescription,
  FormatExampleTitle,
  FormatItem,
  FormatTitle,
  FormatsGrid,
  PDFIcon,
  Recommendation,
  ServiceFormatsContainer,
  Title,
} from './ServiceFormats.styles'

export const ServiceFormats: React.FC = () => {
  const { t } = useTranslation()

  const isStringArray = (value: unknown): value is string[] => {
    return Array.isArray(value) && value.every(item => typeof item === 'string')
  }

  const outstaffDescription = t('cases.outstaffDescription', { returnObjects: true })

  return (
    <ServiceFormatsContainer>
      <Title>{t('cases.cooperationFormats')}</Title>
      <FormatsGrid>
        <FormatItem>
          <Box>
            <FormatTitle>{t('cases.fixPrice')}</FormatTitle>
            <FormatDescription>{t('cases.fixPriceDescription')}</FormatDescription>
          </Box>
          <FormatExampleTitle>{t('cases.projectExample')}</FormatExampleTitle>
          <FormatExampleDescription>{t('cases.fixPriceExample')}</FormatExampleDescription>
        </FormatItem>
        <FormatItem>
          <Box>
            <FormatTitle>
              {t('cases.timeAndMaterials')} <Recommendation>{t('cases.recommended')}</Recommendation>
            </FormatTitle>
            <FormatDescription>{t('cases.timeAndMaterialsDescription')}</FormatDescription>
          </Box>
          <FormatExampleTitle>{t('cases.projectExample')}</FormatExampleTitle>
          <FormatExampleDescription>{t('cases.timeAndMaterialsExample')}</FormatExampleDescription>
        </FormatItem>
        <FormatItem>
          <Box>
            <FormatTitle>{t('cases.retainer')}</FormatTitle>
            <FormatDescription>{t('cases.retainerDescription')}</FormatDescription>
          </Box>

          <FormatExampleTitle>{t('cases.projectExample')}</FormatExampleTitle>
          <FormatExampleDescription>{t('cases.retainerExample')}</FormatExampleDescription>
        </FormatItem>
        <FormatItem>
          <FormatTitle>{t('cases.outstaff')}</FormatTitle>
          <FormatDescription>
            {isStringArray(outstaffDescription)
              ? outstaffDescription.map(item => <div key={`key${item}`}>{item}</div>)
              : null}
          </FormatDescription>
        </FormatItem>
      </FormatsGrid>
      <CallToActionContainer>
        <PDFIcon />
        <CallToActionText>
          {t('cases.guideText')}
          <br />
          <p>{t('cases.flexibleFormats')}</p>
        </CallToActionText>
        <CallToActionButton href={pdf} download>
          <img src={downloadIcon} alt={t('cases.downloadIconAlt')} />
          {t('cases.downloadPresentation')}
        </CallToActionButton>
      </CallToActionContainer>
    </ServiceFormatsContainer>
  )
}
