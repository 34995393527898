import React from 'react'

import { Project } from '../../constants/projects'
import { Arrow, Box, Card, Content, GridContainer, Image, Tag, Tags, Title } from './Projects.styles'

interface ProjectsProps {
  projects: Project[]
}

export const Projects: React.FC<ProjectsProps> = ({ projects }) => {
  return (
    <GridContainer>
      {projects.map(item => (
        <Card>
          <Image src={item.image} alt={item.title} />
          <Content>
            <Box>
              <Title>{item.title}</Title>
              <Tags>
                {item.tags.map(tag => (
                  <Tag>{tag}</Tag>
                ))}
              </Tags>
            </Box>
            <Arrow>&#x2192;</Arrow>
          </Content>
        </Card>
      ))}
    </GridContainer>
  )
}
