import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import deIcon from '../../assets/media/flags/de.png'
import enIcon from '../../assets/media/flags/en.png'
import plIcon from '../../assets/media/flags/pl.png'
import ruIcon from '../../assets/media/flags/ru.png'
import {
  Input,
  Option,
  OptionsContainer,
  SelectContainer,
  SelectLabel,
} from './LanguageSelector.styles'

interface LanguageSelectorProps {
  theme?: 'light' | 'dark'
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  theme = 'dark',
}) => {
  const { i18n } = useTranslation()
  const [selected, setSelected] = useState(i18n.language)
  const [isActive, setIsActive] = useState(false)

  const options = [
    { value: 'en', icon: enIcon },
    { value: 'ru', icon: ruIcon },
    { value: 'de', icon: deIcon },
    { value: 'pl', icon: plIcon },
  ]

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newLanguage = event.target.value

    setSelected(newLanguage)
    i18n.changeLanguage(newLanguage)
    setIsActive(false)
  }

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsActive(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <SelectContainer ref={containerRef} theme={theme}>
      <SelectLabel onClick={() => setIsActive(!isActive)} theme={theme}>
        <img
          src={options.find(option => option.value === selected)?.icon}
          alt={selected}
        />
      </SelectLabel>
      <OptionsContainer isActive={isActive} theme={theme}>
        {options.map((option, index) => (
          <React.Fragment key={option.value}>
            <Input
              className='selectopt'
              name='language'
              type='radio'
              id={`opt${index + 1}`}
              value={option.value}
              checked={selected === option.value}
              onChange={handleChange}
            />
            <Option
              htmlFor={`opt${index + 1}`}
              className={`option ${
                selected === option.value ? 'selected' : ''
              }`}
              theme={theme}
            >
              <img src={option.icon} alt={option.value} />
            </Option>
          </React.Fragment>
        ))}
      </OptionsContainer>
    </SelectContainer>
  )
}
