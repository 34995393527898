import { useTranslation } from 'react-i18next'

import case4Img from '../assets/media/case_1.jpg'
import case3Img from '../assets/media/case_2.jpg'
import case2Img from '../assets/media/case_3.jpg'
import case1Img from '../assets/media/case_4.jpg'

export interface Project {
  image: string
  title: string
  tags: string[]
}

export const useProjects = (): Project[] => {
  const { t } = useTranslation()

  return [
    {
      image: case1Img,
      title: 'Rainbow',
      tags: [t('services.react'), t('services.Typescript'), t('services.Node js')],
    },
    {
      image: case2Img,
      title: 'IriSunTravel',
      tags: [t('services.Vue'), t('services.Typescript'), t('services.Node js')],
    },
    {
      image: case3Img,
      title: 'YOKITUP',
      tags: [t('services.Angular'), t('services.Typescript'), t('services.Node js')],
    },
    {
      image: case4Img,
      title: 'YOKITUP',
      tags: [t('services.Next'), t('services.Typescript'), t('services.Node js')],
    },
  ]
}

export const useFullListProjects = (): Project[] => {
  const { t } = useTranslation()

  return [
    {
      image: case1Img,
      title: 'YOKITUP',
      tags: [t('services.react'), t('services.Typescript'), t('services.Node js')],
    },
    {
      image: case2Img,
      title: 'YOKITUP',
      tags: [t('services.Vue'), t('services.Typescript'), t('services.Node js')],
    },
    {
      image: case3Img,
      title: 'YOKITUP',
      tags: [t('services.Angular'), t('services.Typescript'), t('services.Node js')],
    },
    {
      image: case4Img,
      title: 'YOKITUP',
      tags: [t('services.Next'), t('services.Typescript'), t('services.Node js')],
    },
    {
      image: case1Img,
      title: 'YOKITUP',
      tags: [t('services.react'), t('services.Typescript'), t('services.Node js')],
    },
    {
      image: case2Img,
      title: 'YOKITUP',
      tags: [t('services.Vue'), t('services.Typescript'), t('services.Node js')],
    },
    {
      image: case3Img,
      title: 'YOKITUP',
      tags: [t('services.Angular'), t('services.Typescript'), t('services.Node js')],
    },
    {
      image: case4Img,
      title: 'YOKITUP',
      tags: [t('services.Next'), t('services.Typescript'), t('services.Node js')],
    },
  ]
}
