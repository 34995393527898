import styled from 'styled-components'

export const Section = styled.section`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-size: 70px;
  font-weight: 600;
  margin-bottom: 50px;
`

export const Subtitle = styled.p`
  font-size: 20px;
  color: #666;
  margin-bottom: 40px;
  width: 50%;
`

export const Highlight = styled.span`
  color: #8b73ff;
`

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`

export const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`
