import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import facebook from '../../assets/media/social/facebook.svg'
import instagram from '../../assets/media/social/instagram.svg'
import linkedin from '../../assets/media/social/linkedin.svg'
import telegram from '../../assets/media/social/telegram.svg'
import upwork from '../../assets/media/social/upwork.svg'
import { BREAK_POINTS } from '../../constants/theme'
import useModal from '../../hooks/useModal'
import { Icon } from '../Icon/Icon'
import { LanguageSelector } from '../LanguageSelector/LanguageSelector'
import { Modal } from '../Modal/Modal'

const SidebarContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 130px;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${BREAK_POINTS.tablet}) {
    right: 20px;
  }
`

const CloseButtonLine = styled.div`
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #fff;

  &:first-child {
    transform: rotate(45deg);
  }

  &:last-child {
    transform: rotate(-45deg);
  }
`

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${BREAK_POINTS.mobile}) {
    align-items: start;
    margin: 70px 50px 50px 0;
    flex-grow: 0;
  }

  li {
    margin: 20px 0;
    font-family: 'Gilroy', sans-serif;
    font-weight: 700;
    font-size: 50px;

    a {
      text-decoration: none;
      color: #000;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (max-width: ${BREAK_POINTS.tablet}) {
      font-size: 36px;
    }

    @media (max-width: ${BREAK_POINTS.mobile}) {
      font-size: 28px;
    }
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Footer = styled.div`
  padding: 20px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100px;
  width: 40%;

  @media (max-width: 1440px) {
    width: 80%;
    flex-direction: column;
    align-items: center;
    height: 200px;
  }
  @media (max-width: ${BREAK_POINTS.mobile}) {
    width: 80%;
    flex-direction: column;
    align-items: center;
    height: 200px;
  }
`

const SocialIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  a {
    margin: 0 10px;
  }

  img {
    width: 30px;
    height: 30px;
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    margin-bottom: 20px;
  }
`

const DiscussButton = styled.div`
  font-family: inherit;
  display: none;
  align-items: center;
  padding: 15px 30px;
  background-color: #000;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s, box-shadow 0.5s, color 0.5s;
  border: none;
  cursor: pointer;

  @media (max-width: 1300px) {
    display: flex;
  }

  &:hover {
    background-color: #333;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    color: #ffffff;

    &::after {
      filter: invert(1);
      transform: rotate(45deg);
    }
  }
`

const ContactInfo = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-size: 16px;
  color: #000;
  text-align: center;

  a {
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${BREAK_POINTS.mobile}) {
    font-size: 14px;
  }
`

interface SidebarMenuProps {
  isOpen: boolean
  onClose: () => void
}

export const SidebarMenu: React.FC<SidebarMenuProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const { isOpen: isOpenModal, handleOpen, handleClose } = useModal()

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <SidebarContainer isOpen={isOpen}>
      <CloseButtonContainer onClick={onClose}>
        <CloseButtonLine />
        <CloseButtonLine />
      </CloseButtonContainer>
      <MenuList>
        <li>
          <Link to='/cases' onClick={onClose}>
            {t('cases.cases')}
          </Link>
        </li>
        <li>
          <Link to='/services' onClick={onClose}>
            {t('services.services')}
          </Link>
        </li>
        <li>
          <Link to='/company' onClick={onClose}>
            {t('company.company')}
          </Link>
        </li>
        <li>
          <Link to='/careers' onClick={onClose}>
            {t('careers.careers')}
          </Link>
        </li>
        <li>
          <Link to='/blog' onClick={onClose}>
            {t('main.blog')}
          </Link>
        </li>
        <li>
          <Link to='/contact' onClick={onClose}>
            {t('contacts.contact')}
          </Link>
        </li>
      </MenuList>
      <DiscussButton onClick={handleOpen}>{t('main.discussButton')}</DiscussButton>
      <Footer>
        <SocialIcons>
          <Icon src={upwork} alt='Upwork' />
          <Icon src={linkedin} alt='Linkedin' />
          <Icon src={telegram} alt='Telegram' />
          <Icon src={facebook} alt='Facebook' />
          <Icon src={instagram} alt='Instagram' />
        </SocialIcons>
        <Box>
          <LanguageSelector theme='light' />
          <ContactInfo>
            <p>info@oralex.com</p>
          </ContactInfo>
        </Box>
      </Footer>
      <Modal isOpen={isOpenModal} onClose={handleClose} />
    </SidebarContainer>
  )
}
