import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFullListProjects } from '../../constants/projects'
import { Projects } from '../Projects/Projects'
import { Highlight, Section, Subtitle, Title } from './CasesMain.styles'

export const CasesMain: React.FC = () => {
  const { t } = useTranslation()
  const fullListProjects = useFullListProjects()

  return (
    <Section>
      <Title>{t('cases.cases')}</Title>
      <Subtitle>
        {t('cases.completedProjects')}
        <Highlight> {t('cases.industriesList')}</Highlight>
      </Subtitle>
      <Projects projects={fullListProjects} />
    </Section>
  )
}
