import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 100px 0;
`

export const Card = styled.div`
  background: #f5f5f5;
  border-radius: 40px;
  padding: 40px;
  width: calc(33.33333% - 100px);
  display: flex;
  flex-direction: column;
  align-items: baseline;
`

export const DepartmentTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
`

export const Description = styled.p`
  font-size: 18px;
  color: rgba(19, 21, 29, 0.7);
  font-weight: 500;
  text-align: center;
`

export const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
`

export const Name = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
`

export const Position = styled.p`
  color: rgba(19, 21, 29, 0.7);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
`

export const TelegramButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  color: #fff;
  padding: 20px 30px;
  border-radius: 15px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;

  img {
    width: 20px;
    filter: invert(100%);
    margin-right: 15px;
  }
`

export const Box = styled.div`
  display: flex;
  width: 60%;
  justify-content: start;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
`

export const Email = styled.a`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
`
