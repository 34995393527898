import { Route, Routes } from 'react-router'

import { BlogMain } from './components/BlogMain/BlogMain'
import { Layout } from './layout/Layout'
import { Careers } from './pages/Careers'
import { Cases } from './pages/Cases'
import { Company } from './pages/Company'
import { Contacts } from './pages/Contacts'
import { SoftEngineerSenior } from './pages/Jobs/SoftEngineerSenior'
import { UIDesignerMiddle } from './pages/Jobs/UIDesignerMiddle'
import { Main } from './pages/Main'
import { Services } from './pages/Services'

export const App: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Main />} />
        <Route path='cases' element={<Cases />} />
        <Route path='services' element={<Services />} />
        <Route path='company' element={<Company />} />
        <Route path='careers' element={<Careers />} />
        <Route
          path='careers/ux-ui-desinger-middle'
          element={<UIDesignerMiddle />}
        />
        <Route
          path='careers/soft-engineer-senior'
          element={<SoftEngineerSenior />}
        />

        <Route path='contact' element={<Contacts />} />
        <Route path='blog' element={<BlogMain />} />
      </Route>
    </Routes>
  )
}
