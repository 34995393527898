import React from 'react'
import { useTranslation } from 'react-i18next'

import team from '../../assets/media/team.jpg'
import {
  Highlight,
  Image,
  ImageContainer,
  Section,
  Subtitle,
  Title,
} from './CareersMain.styles'

export const CareersMain: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <Title>{t('careers.careersInOralex')}</Title>
      <Subtitle>
        <Highlight>{t('careers.bePartOfStrongTeam')}</Highlight>,{' '}
        {t('careers.beInEpicenter')}
      </Subtitle>
      <ImageContainer>
        <Image src={team} alt='Everest the movie' />
      </ImageContainer>
    </Section>
  )
}
