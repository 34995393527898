import styled from 'styled-components'

import arrowIcon from '../../assets/media/arrow.svg'

export const ButtonContainer = styled.button`
  font-family: inherit;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  background-color: #ffffff;
  border-radius: 10px;
  text-decoration: none;
  color: #000;
  font-size: 24px;
  font-weight: 600;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s, box-shadow 0.5s, color 0.5s;
  z-index: 99;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #333;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    color: #ffffff;

    &::after {
      filter: invert(1);
      transform: rotate(45deg);
    }
  }

  &::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    margin-left: 15px;
    background-image: url(${arrowIcon});
    background-size: contain;
    background-repeat: no-repeat;
    transition: filter 0.5s, transform 0.5s;
    filter: invert(0);
  }

  @media (max-width: 1300px) {
    display: none;
  }
`

export const ProfileImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
`
