import { createGlobalStyle } from 'styled-components'

import GilroyBlack from '../assets/fonts/Gilroy-Black.ttf'
import GilroyBold from '../assets/fonts/Gilroy-Bold.ttf'
import GilroyHeavy from '../assets/fonts/Gilroy-Heavy.ttf'
import GilroyLight from '../assets/fonts/Gilroy-Light.ttf'
import GilroyMedium from '../assets/fonts/Gilroy-Medium.ttf'
import GilroyRegular from '../assets/fonts/Gilroy-Regular.ttf'
import GilroySemiBold from '../assets/fonts/Gilroy-Semibold.ttf'
import GilroyThin from '../assets/fonts/Gilroy-Thin.ttf'
import GilroyUltraLight from '../assets/fonts/Gilroy-UltraLight.ttf'

const fonts = [
  { family: 'Gilroy', src: GilroyBlack, weight: 900, style: 'normal' },
  { family: 'Gilroy', src: GilroyBold, weight: 700, style: 'normal' },
  { family: 'Gilroy', src: GilroyHeavy, weight: 900, style: 'normal' },
  { family: 'Gilroy', src: GilroyLight, weight: 300, style: 'normal' },
  { family: 'Gilroy', src: GilroySemiBold, weight: 600, style: 'normal' },
  { family: 'Gilroy', src: GilroyMedium, weight: 500, style: 'normal' },
  { family: 'Gilroy', src: GilroyRegular, weight: 400, style: 'normal' },
  { family: 'Gilroy', src: GilroyThin, weight: 100, style: 'normal' },
  { family: 'Gilroy', src: GilroyUltraLight, weight: 200, style: 'normal' },
]

const fontFaces = fonts
  .map(
    font => `
  @font-face {
    font-family: '${font.family}';
    src: url(${font.src}) format('truetype');
    font-weight: ${font.weight};
    font-style: ${font.style};
  }
`,
  )
  .join('\n')

const GlobalStyle = createGlobalStyle`
  ${fontFaces}
  * {
    font-family: 'Gilroy', sans-serif !important;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Gilroy', sans-serif;
    background-color: #fff;
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -.02em;
    line-height: 32px;
  }

  a {
    text-decoration: none;
    color: inherit;
    list-style: none;
    list-style-type: none;
    outline: 0;
  }

  h1, h2, h3, span {
    padding: 0;
    margin: 0;
  }

  p {
    padding: 0;
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }




  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
  }

  .slick-track {
    position: relative;
    display: flex;
    align-items: center;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`

export default GlobalStyle
