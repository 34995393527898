import React from 'react'

import { IconContainer, Line } from './MenuIcon.styles'

interface MenuIconProps {
  onClick: () => void
}

export const MenuIcon: React.FC<MenuIconProps> = ({ onClick }) => {
  return (
    <IconContainer onClick={onClick}>
      <Line />
      <Line />
      <Line />
    </IconContainer>
  )
}
