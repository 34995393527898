import { useState } from 'react'

interface UseModalReturn {
  isOpen: boolean
  handleOpen: () => void
  handleClose: () => void
}

const useModal = (): UseModalReturn => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleOpen = (): void => setIsOpen(true)
  const handleClose = (): void => setIsOpen(false)

  return {
    isOpen,
    handleOpen,
    handleClose,
  }
}

export default useModal
