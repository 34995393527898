import { TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import crossIcon from '../../assets/media/cross.svg'
import fileIcon from '../../assets/media/file.svg'
import {
  AcceptanceText,
  AttachText,
  CustomDialogContent,
  CustomDialogText,
  CustomDialogTitle,
  FileInputContainer,
  FormContainer,
  IconButton,
  InlineFieldsContainer,
  Input,
  Label,
  StyledButton,
  StyledDialog,
  SubmitSection,
  Title,
} from './Modal.styles'

export const Modal: React.FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()

  return (
    <StyledDialog open={isOpen} onClose={onClose} maxWidth='xl' fullWidth>
      <CustomDialogTitle>
        <Title>{t('main.discussProject')}</Title>
        <IconButton onClick={onClose}>
          <img src={crossIcon} alt='Cross Icon' />
        </IconButton>
      </CustomDialogTitle>
      <CustomDialogContent>
        <CustomDialogText>{t('main.fillForm')}</CustomDialogText>
        <FormContainer component='form'>
          <TextField
            required
            fullWidth
            variant='outlined'
            label={t('main.yourName')}
            InputProps={{
              style: {
                borderRadius: '15px',
              },
            }}
          />
          <InlineFieldsContainer>
            <TextField
              required
              fullWidth
              variant='outlined'
              label='E-mail'
              InputProps={{
                style: {
                  borderRadius: '15px',
                },
              }}
            />
            <TextField
              required
              fullWidth
              variant='outlined'
              label={t('main.phoneOrMessenger')}
              InputProps={{
                style: {
                  borderRadius: '15px',
                },
              }}
            />
          </InlineFieldsContainer>
          <TextField
            fullWidth
            variant='outlined'
            label={t('main.projectDescription')}
            multiline
            rows={6}
            InputProps={{
              style: {
                borderRadius: '15px',
                height: '140px',
              },
            }}
          />
          <FileInputContainer>
            <Label>
              <img src={fileIcon} alt='File Icon' />
              <Input type='file' accept='.pdf,.doc,.jpeg,.png' />
              <AttachText>{t('main.attachFile')}</AttachText>
            </Label>
            <Typography>{t('main.fileFormat')}</Typography>
          </FileInputContainer>
        </FormContainer>
      </CustomDialogContent>
      <SubmitSection>
        <StyledButton>{t('main.submitApplication')}</StyledButton>
        <AcceptanceText>
          {t('main.acceptanceTextSubmit')}{' '}
          <a href='#'>{t('main.dataProcessingTerms')}</a>
        </AcceptanceText>
      </SubmitSection>
    </StyledDialog>
  )
}
