import React from 'react'
import { useTranslation } from 'react-i18next'

import facebook from '../../assets/media/social/facebook.svg'
import instagram from '../../assets/media/social/instagram.svg'
import telegram from '../../assets/media/social/telegram.svg'
import upwork from '../../assets/media/social/upwork.svg'
import { Icon } from '../Icon/Icon'
import {
  ContactButton,
  Copyright,
  FooterContainer,
  LinkItem,
  LinkList,
  Section,
  SocialIcons,
  Title,
} from './Footer.styles'

// import { LanguageSelector } from '../LanguageSelector/LanguageSelector'

export const Footer: React.FC = () => {
  const { t } = useTranslation()

  const getCurrentYear = (): number => new Date().getFullYear()

  return (
    <FooterContainer>
      <Section>
        <LinkList>
          <LinkItem>
            <a href='/cases'>{t('cases.cases')}</a>
          </LinkItem>
          <LinkItem>
            <a href='/services'>{t('services.services')}</a>
          </LinkItem>
          <LinkItem>
            <a href='/company'>{t('company.aboutCompany')}</a>
          </LinkItem>
          <LinkItem>
            <a href='/blog'>{t('main.blog')}</a>
          </LinkItem>
        </LinkList>
        <Copyright>
          © ORALEX {getCurrentYear()}. {t('main.rights')}
        </Copyright>
      </Section>
      <Section>
        <Title>info@oralex.com</Title>
        <ContactButton href='mailto:info@oralex.com'>{t('main.contactUs')}</ContactButton>
      </Section>
      <Section>
        <SocialIcons>
          <Icon src={telegram} alt='Telegram' theme='dark' />
          <Icon src={upwork} alt='Upwork' theme='dark' />
          <Icon src={facebook} alt='Facebook' theme='dark' />
          <Icon src={instagram} alt='Instagram' theme='dark' />
        </SocialIcons>
        {/* <LanguageSelector /> */}
      </Section>
    </FooterContainer>
  )
}
