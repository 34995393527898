import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { styled } from 'styled-components'

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const InlineFieldsContainer = styled(Box)`
  display: flex;
  gap: 20px;
`

export const FileInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`

export const Title = styled.h2`
  font-size: 50px;
  font-weight: 700;
`

export const CustomDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`

export const CustomDialogText = styled.p`
  color: #666;
  margin-bottom: 20px;
`

export const CustomDialogContent = styled(DialogContent)`
  padding: 0 20px 20px 20px;
`

export const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    backdrop-filter: blur(200px);
    background-color: rgba(0, 0, 0, 1);
  }
  .MuiPaper-root {
    padding: 40px;
    border-radius: 20px;
    position: relative;
    width: 700px;
    height: 700px;
    max-width: 95%;
    max-height: 95%;
  }
`

export const AcceptanceText = styled(Typography)`
  font-size: 14px;
  color: #666;
  margin-left: 20px;
  width: 400px;
  a {
    color: #6c63ff;
  }
`

export const StyledButton = styled.div`
  background-color: #6c63ff;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 15px;
  &:hover {
    cursor: pointer;
    background-color: #5b52e6;
  }
`

export const IconButton = styled.div`
  width: 40px;
  height: 40px;
  img {
    width: 40px;
  }

  &:hover {
    cursor: pointer;
  }
`

export const Input = styled.input`
  display: none;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 30px;
    margin-right: 8px;
  }
`

export const AttachText = styled.p`
  font-weight: 600;
  font-size: 20px;
`

export const SubmitSection = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`
