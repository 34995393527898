import React from 'react'
import { useTranslation } from 'react-i18next'

import useModal from '../../hooks/useModal'
import { Modal } from '../Modal/Modal'
import {
  ConsultButton,
  Highlight,
  ServiceCount,
  ServiceDescription,
  ServiceItem,
  ServiceList,
  ServiceName,
  ServiceTitle,
  ServicesContainer,
  Subtitle,
  Title,
} from './ServicesMain.styles'

export const ServicesMain: React.FC = () => {
  const { t } = useTranslation()
  const { isOpen, handleOpen, handleClose } = useModal()

  const services = [
    {
      title: t('services.productAudit'),
      description: t('services.productAuditDescription'),
      count: t('services.productAuditCount'),
    },
    {
      title: t('services.webAnalytics'),
      description: t('services.webAnalyticsDescription'),
      count: t('services.webAnalyticsCount'),
    },
    {
      title: t('services.research'),
      description: t('services.researchDescription'),
      count: t('services.researchCount'),
    },
    {
      title: t('services.design'),
      description: t('services.designDescription'),
      count: t('services.designCount'),
    },
    {
      title: t('services.development'),
      description: t('services.developmentDescription'),
      count: t('services.developmentCount'),
    },
    {
      title: t('services.productDevelopment'),
      description: t('services.productDevelopmentDescription'),
      count: t('services.productDevelopmentCount'),
    },
    {
      title: t('services.designSupport'),
      description: t('services.designSupportDescription'),
      count: t('services.designSupportCount'),
    },
    {
      title: t('services.noCodeDevelopment'),
      description: t('services.noCodeDevelopmentDescription'),
      count: t('services.noCodeDevelopmentCount'),
    },
    {
      title: t('services.motionDesign'),
      description: t('services.motionDesignDescription'),
      count: t('services.motionDesignCount'),
    },
    {
      title: t('services.outsourcingUXTeam'),
      description: t('services.outsourcingUXTeamDescription'),
      count: t('services.outsourcingUXTeamCount'),
    },
  ]

  return (
    <ServicesContainer>
      <Title>{t('services.services')}</Title>
      <Subtitle>
        {t('services.createEffectiveDigitalProducts')} <Highlight>{t('services.thatHelpCompanies')}</Highlight>
      </Subtitle>
      <ServiceList>
        {services.map(service => (
          <ServiceItem key={service.title}>
            <ServiceName>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceCount>{service.count}</ServiceCount>
            </ServiceName>
            <ServiceDescription>{service.description}</ServiceDescription>
            <ConsultButton className='consult-button' onClick={handleOpen}>
              {t('services.getConsultation')}
            </ConsultButton>
          </ServiceItem>
        ))}
      </ServiceList>
      <Modal isOpen={isOpen} onClose={handleClose} />
    </ServicesContainer>
  )
}
