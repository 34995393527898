import { ContactsBlock } from '../components/ContactsBlock/ContactsBlock'
import { JobBlock } from '../components/JobBlock/JobBlock'
import { LogoSlider } from '../components/LogoSlider/LogoSlider'
import { OfferBlock } from '../components/OfferBlock/OfferBlock'
import { Projects } from '../components/Projects/Projects'
import { TestimonialsSlider } from '../components/TestimonialsSlider/TestimonialsSlider'
import { useProjects } from '../constants/projects'

export const Main: React.FC = () => {
  const projects = useProjects()

  return (
    <>
      <OfferBlock />
      <LogoSlider />
      <Projects projects={projects} />
      <TestimonialsSlider />
      <JobBlock />
      <ContactsBlock />
    </>
  )
}
