import { DialogContent, TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import fileIcon from '../../assets/media/file.svg'
import {
  AcceptanceText,
  AttachText,
  Container,
  CustomDialogText,
  CustomDialogTitle,
  FileInputContainer,
  FormContainer,
  InlineFieldsContainer,
  Input,
  Label,
  StyledButton,
  SubmitSection,
  Title,
} from './JobForm.styles'

export const JobForm: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <CustomDialogTitle>
        <Title>{t('careers.becomePartOfOralex')}</Title>
      </CustomDialogTitle>
      <DialogContent>
        <CustomDialogText>{t('careers.tellUsAboutYourself')}</CustomDialogText>
        <FormContainer component='form'>
          <TextField
            required
            fullWidth
            variant='outlined'
            label={t('careers.fullName')}
            InputProps={{
              style: {
                borderRadius: '15px',
              },
            }}
          />
          <InlineFieldsContainer>
            <TextField
              required
              fullWidth
              variant='outlined'
              label='E-mail'
              InputProps={{
                style: {
                  borderRadius: '15px',
                },
              }}
            />
            <TextField
              required
              fullWidth
              variant='outlined'
              label={t('careers.phoneOrMessenger')}
              InputProps={{
                style: {
                  borderRadius: '15px',
                },
              }}
            />
          </InlineFieldsContainer>
          <TextField
            fullWidth
            variant='outlined'
            label={t('careers.coverLetter')}
            multiline
            rows={6}
            InputProps={{
              style: {
                borderRadius: '15px',
              },
            }}
          />
          <FileInputContainer>
            <Label>
              <img src={fileIcon} alt='File Icon' />
              <Input type='file' accept='.pdf,.doc,.jpeg,.png' />
              <AttachText>{t('careers.attachFile')}</AttachText>
            </Label>
            <Typography>{t('careers.fileFormat')}</Typography>
          </FileInputContainer>
        </FormContainer>
      </DialogContent>
      <SubmitSection>
        <StyledButton>{t('careers.apply')}</StyledButton>
        <AcceptanceText>
          {t('careers.acceptanceText')}{' '}
          <a href='#'>{t('careers.dataProcessingTerms')}</a>
        </AcceptanceText>
      </SubmitSection>
    </Container>
  )
}
