import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Address,
  AddressText,
  Button,
  City,
  ContactInfo,
  Container,
  Email,
  Subtitle,
  Title,
} from './ContactsBlock.styles'

export const ContactsBlock: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <ContactInfo>
        <Title>{t('contacts.contact')}</Title>
        <Subtitle>{t('contacts.writeToUs')}</Subtitle>
        <Email href='mailto:info@oralex.com'>info@oralex.com</Email>
        <Button href='/discuss'>{t('main.discussButton')}</Button>
      </ContactInfo>
      <Address>
        <City>{t('contacts.moscow')}</City>
        <AddressText>{t('contacts.moscowAddress')}</AddressText>
        <City>{t('contacts.tambov')}</City>
        <AddressText>{t('contacts.tambovAddress')}</AddressText>
      </Address>
    </Container>
  )
}
