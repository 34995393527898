import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { animated, config, useSpring } from 'react-spring'

import { OfferBlockContainer, Tag, TagsContainer, Title } from './OfferBlock.styles'

const tagContents = {
  banks: ['fintech', 'logistics', 'healthcare'],
  retail: ['retail', 'e-commerce', 'education'],
  telecom: ['web3', 'gaming'],
}

const tagColors = {
  banks: ['#607bde', '#f732d6', '#0fb883'],
  retail: ['#be740c', '#35007a', '#2c2c2c'],
  telecom: ['#3bbe50', '#ff4b41'],
}

type TagType = keyof typeof tagContents

const tags: TagType[] = Object.keys(tagContents) as TagType[]

export const OfferBlock: React.FC = () => {
  const { t } = useTranslation()
  const [activeTag, setActiveTag] = useState<TagType>('banks')
  const [activeTextIndex, setActiveTextIndex] = useState(0)

  const titleProps = useSpring({
    from: { opacity: 0, transform: 'translate3d(0, -40px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    config: config.gentle,
    delay: 300,
  })

  const tagsProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.stiff,
    delay: 400,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTextIndex(prevIndex => {
        const newIndex = (prevIndex + 1) % tagContents[activeTag].length

        if (newIndex === 0) {
          setActiveTag(prevTag => {
            const currentIndex = tags.indexOf(prevTag)
            const nextIndex = (currentIndex + 1) % tags.length

            return tags[nextIndex]
          })
        }

        return newIndex
      })
    }, 3000)

    return () => clearInterval(interval)
  }, [activeTag])

  return (
    <OfferBlockContainer>
      <animated.div style={titleProps}>
        <Title>{t('main.mainTitle')}</Title>
      </animated.div>
      <animated.div style={tagsProps}>
        <TagsContainer>
          {tags.map(tag => (
            <Tag
              key={tag}
              isActive={activeTag === tag}
              bgColor={activeTag === tag ? tagColors[tag][activeTextIndex] : '#F0F0F0'}
            >
              <animated.div>{t(`main.${tagContents[tag][activeTag === tag ? activeTextIndex : 0]}`)}</animated.div>
            </Tag>
          ))}
          <Tag key='tag-otherIndustries' isActive={false} bgColor='#F0F0F0'>
            <animated.div>{t(`main.otherIndustries`)}</animated.div>
          </Tag>
        </TagsContainer>
      </animated.div>
    </OfferBlockContainer>
  )
}
