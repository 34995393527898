import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 100px;
`

export const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
`

export const Email = styled.a`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
  display: block;
`

export const AddressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 70px 0;
  width: 80%;
`

export const AddressBlock = styled.div`
  width: 45%;
`

export const AddressTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`

export const Address = styled.p`
  font-size: 24px;
  font-weight: 600;
`

export const SocialIconsContainer = styled.div`
  display: flex;
  gap: 20px;
`
