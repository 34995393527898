import { ServiceFormats } from '../components/ServiceFormats/ServiceFormats'
import { ServicesMain } from '../components/ServicesMain/ServicesMain'

export const Services: React.FC = () => {
  return (
    <>
      <ServicesMain />
      <ServiceFormats />
    </>
  )
}
