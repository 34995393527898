import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

import { App } from './App'
import i18n from './locales/i18n'
import GlobalStyle from './styles/globalStyles'

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <GlobalStyle />
      <App />
    </BrowserRouter>
  </I18nextProvider>,
)
