import React from 'react'

import { IconImage, IconWrapper } from './Icon.styles'

interface IconProps {
  src: string
  alt: string
  theme?: 'dark' | 'light'
}

export const Icon: React.FC<IconProps> = ({ src, alt, theme = 'light' }) => {
  return (
    <IconWrapper theme={theme}>
      <IconImage src={src} alt={alt} />
    </IconWrapper>
  )
}
