import { ContactsMain } from '../components/ContactsMain/ContactsMain'
import { Departments } from '../components/Departments/Departments'

export const Contacts: React.FC = () => {
  return (
    <>
      <ContactsMain />
      <Departments />
    </>
  )
}
