import React from 'react'
import { useTranslation } from 'react-i18next'

import profileImg from '../../assets/media/call.jpg'
import telegram from '../../assets/media/social/telegram.svg'
import {
  Avatar,
  Box,
  Card,
  Container,
  DepartmentTitle,
  Description,
  Email,
  Name,
  Position,
  TelegramButton,
} from './Departments.styles'

const departments = [
  {
    title: 'contacts.prDepartment',
    description: 'contacts.cooperationQuestions',
    avatar: profileImg,
    name: 'Lena',
    position: 'Development Director',
    telegramLink: '#',
    email: 'pr@everest.cx',
  },
  {
    title: 'contacts.salesDepartment',
    description: 'contacts.servicesConsultation',
    avatar: profileImg,
    name: 'Olya',
    position: 'Account Director',
    telegramLink: '#',
    email: 'info@everest-media.ru',
  },
  {
    title: 'contacts.hrDepartment',
    description: 'contacts.employmentQuestions',
    avatar: profileImg,
    name: 'Lena',
    position: 'Development Director',
    telegramLink: '#',
    email: 'hh@everest-media.ru',
  },
]

export const Departments: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      {departments.map(department => (
        <Card key={department.email}>
          <DepartmentTitle>{t(department.title)}</DepartmentTitle>
          <Description>{t(department.description)}</Description>
          <Box>
            <Avatar src={department.avatar} alt={department.name} />
            <div>
              <Name>{department.name}</Name>
              <Position>{department.position}</Position>
            </div>
          </Box>
          <TelegramButton href={department.telegramLink}>
            <img src={telegram} alt='telegram' />
            {t('contacts.writeInTelegram')}
          </TelegramButton>
          <Description>{t('contacts.orEmail')}</Description>
          <Email href={`mailto:${department.email}`}>{department.email}</Email>
        </Card>
      ))}
    </Container>
  )
}
