import styled from 'styled-components'

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #333;
    border-color: #333;
  }

  &:hover div {
    background-color: #fff;
  }
`

export const Line = styled.div`
  width: 25px;
  height: 2px;
  background-color: #000;
  margin: 3px 0;
  transition: background-color 0.3s;
`
