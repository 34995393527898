import styled from 'styled-components'

import pdfIcon from '../../assets/media/pdf.svg'

export const ServiceFormatsContainer = styled.div`
  margin: 100px 0;
`

export const Title = styled.h1`
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 80px;
`

export const FormatsGrid = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 150px;
`

export const FormatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 23%;
  height: 400px;
`

export const Box = styled.div`
  height: 70%;
`

export const FormatTitle = styled.h2`
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 30px;
`

export const FormatDescription = styled.p`
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
`

export const FormatExampleTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`

export const FormatExampleDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  width: 70%;
`

export const Recommendation = styled.span`
  display: inline-block;
  background-color: #7e57c2;
  color: white;
  font-weight: 500;
  font-size: 14px;
  padding: 2px 8px;
  margin-left: 10px;
  border-radius: 5px;
`

export const CallToActionContainer = styled.div`
  background-color: #000;
  color: white;
  padding: 40px 30px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

export const CallToActionText = styled.div`
  font-weight: 400;
  font-size: 24px;

  p {
    color: rgba(265, 265, 265, 0.7);
    padding: 10px 0;
    font-size: 16px;
  }
`

export const CallToActionButton = styled.a`
  background-color: #000;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  padding: 15px 25px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: color 0.5s, background-color 0.5s;

  img {
    width: 30px;
    transition: filter 0.5s;
  }

  &:hover {
    background-color: #fff;
    color: #000;

    img {
      filter: invert(1);
    }
  }
`

export const PDFIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 65%;
  width: 100px;
  height: 100px;
  background: url(${pdfIcon}) no-repeat center center;
  background-size: contain;
  transform: translate(-50%, -50%);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 180px;
    height: 180px;
    background-color: #6147da;
    border-radius: 50%;
    filter: blur(40px);
    opacity: 0.3;
    transform: translate(-50%, -50%);
  }
`
