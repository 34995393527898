import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { NewsList } from '../NewsList/NewsList'

const Container = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 100px;
`

export const BlogMain: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('main.blog')}</Title>
      <NewsList />
    </Container>
  )
}
