import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 88;
  top: 0;
  right: 0;
`

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  margin: 0 100px;

  @media (max-width: 1200px) {
    margin: 0 50px;
  }

  @media (max-width: 768px) {
    margin: 0 20px;
    height: 80px;
  }

  @media (max-width: 480px) {
    margin: 0 10px;
    height: 60px;
    padding: 20px;
  }
`

export const Nav = styled.nav`
  ul {
    display: flex;

    li {
      margin-right: 30px;
      font-size: 16px;
      padding: 10px 12px;
      border-radius: 20px;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #f0f0f0;
        color: #000;
      }

      a {
        font-weight: 500;
        font-size: 18px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 1045px) {
    ul {
      display: none;
    }
  }
`

export const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 80px;

  @media (max-width: 768px) {
    gap: 20px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }
`
