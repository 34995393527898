import styled from 'styled-components'

import { BREAK_POINTS, COLORS } from '../../constants/theme'

export const FooterContainer = styled.footer`
  background-color: #111;
  color: #fff;
  padding: 60px 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${BREAK_POINTS.tablet}) {
    padding: 40px 20px;
  }

  @media (max-width: ${BREAK_POINTS.mobile}) {
    padding: 30px 15px;
  }
`

export const Section = styled.div`
  justify-content: center;
  min-width: 300px;
  margin: 10px 0;

  @media (max-width: ${BREAK_POINTS.tablet}) {
    min-width: 100%;
    text-align: center;
  }
`

export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;

  @media (max-width: ${BREAK_POINTS.tablet}) {
    font-size: 16px;
  }

  @media (max-width: ${BREAK_POINTS.mobile}) {
    font-size: 14px;
  }
`

export const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media (max-width: ${BREAK_POINTS.tablet}) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`

export const LinkItem = styled.li`
  margin-bottom: 10px;

  a {
    color: #fff;
    font-size: 27px;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    margin: 5px 10px;
    font-size: 24px;
  }

  @media (max-width: ${BREAK_POINTS.mobile}) {
    margin: 5px;
    font-size: 20px;
  }
`

export const ContactButton = styled.a`
  display: inline-block;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;

  &:hover {
    background-color: #f0f0f0;
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    padding: 12px 25px;
    font-size: 14px;
  }

  @media (max-width: ${BREAK_POINTS.mobile}) {
    padding: 10px 20px;
    font-size: 12px;
  }
`

export const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #333;
    color: ${COLORS.light};
    text-decoration: none;
    transition: background-color 0.3s;

    &:hover {
      background-color: #555;
    }

    img {
      width: 24px;
      height: 24px;
    }

    @media (max-width: ${BREAK_POINTS.tablet}) {
      width: 35px;
      height: 35px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    @media (max-width: ${BREAK_POINTS.mobile}) {
      width: 30px;
      height: 30px;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
`

export const Copyright = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #333;
  padding-top: 10px;
  font-size: 14px;
  color: #aaa;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
`
