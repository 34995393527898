import styled from 'styled-components'

import arrowIcon from '../../assets/media/arrow.svg'

export const Container = styled.div`
  background-color: #fff;
  margin: 150px 0;
  padding: 20px;

  @media (max-width: 768px) {
    margin: 100px 0;
  }

  @media (max-width: 480px) {
    margin: 50px 0;
  }
`

export const Title = styled.h2`
  font-size: 50px;
  line-height: 58px;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &::after {
    content: '💜';
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 42px;
  }

  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`

export const Description = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`

export const JobList = styled.div`
  margin: 50px 0;
`

export const JobItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin: 10px 0;
  border-top: 1px solid #e0e0e0;
  position: relative;

  &:first-child {
    border-top: none;
  }

  &:hover .arrow-button {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding: 8px 0;
    margin: 8px 0;
  }
`

export const JobDetails = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const Badge = styled.span`
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 10px;
  font-size: 14px;
  display: inline-block;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 4px 8px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    padding: 3px 6px;
  }
`

export const JobTitle = styled.span`
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`

export const Remote = styled.span`
  font-size: 16px;
  color: #777;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`

export const ApplyButton = styled.a`
  display: inline-block;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 500;
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: #333;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 12px 25px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 12px;
  }
`

export const ArrowButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(${arrowIcon});
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;

    &::before {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;

    &::before {
      width: 12px;
      height: 12px;
    }
  }
`
