import { CasesMain } from '../components/CasesMain/CasesMain'
import { ServiceFormats } from '../components/ServiceFormats/ServiceFormats'

export const Cases: React.FC = () => {
  return (
    <>
      <CasesMain />
      <ServiceFormats />
    </>
  )
}
