import styled, { keyframes } from 'styled-components'

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`

export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    z-index: 1;
  }

  &:before {
    left: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
  }

  &:after {
    right: 0;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
  }
`

export const SliderTrack = styled.div`
  display: flex;
  animation: ${scroll} 30s linear infinite;
`

export const LogoContainer = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 60px; // Reduced height for smaller icons
  padding: 10px;
  flex: 0 0 auto; // Prevent logos from stretching
  margin: 0 40px; // Add spacing between logos
`

export const StyledImage = styled.img`
  max-height: 50px;
  max-width: 100%;
  filter: grayscale(100%);
`
