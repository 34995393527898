import { CompanyMain } from '../components/CompanyMain/CompanyMain'
import { ServiceFormats } from '../components/ServiceFormats/ServiceFormats'

export const Company: React.FC = () => {
  return (
    <>
      <CompanyMain />
      <ServiceFormats />
    </>
  )
}
