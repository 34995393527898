export const COLORS = {
  dark: '#000000',
  light: '#ffffff',
  border: '#e0e0e0',
}

export const FONT_SIZE = {
  small: '14px',
  medium: '16px',
  large: '20px',
  xLarge: '24px',
}

export const BREAK_POINTS = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1024px',
}
