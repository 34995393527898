import styled from 'styled-components'

export const ServicesContainer = styled.div`
  margin-top: 200px;
`

export const Title = styled.h1`
  font-size: 70px;
  font-weight: 600;
  margin-bottom: 50px;
`

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 50px;
`

export const Highlight = styled.span`
  color: #7e57c2;
`

export const ServiceList = styled.ul`
  margin: 40px 0;
`

export const ServiceItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 50px 0;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }

  &:hover .consult-button {
    opacity: 1;
    visibility: visible;
  }
`
export const ServiceName = styled.div`
  width: 30%;
`

export const ServiceTitle = styled.h2`
  font-weight: 600;
  font-size: 24px;
  margin: 0;
`

export const ServiceDescription = styled.p`
  width: 40%;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
`

export const ServiceCount = styled.p`
  font-weight: 400;
  font-size: 18px;
  margin: 0;
`
export const ConsultButton = styled.button`
  font-weight: 500;
  font-size: 20px;
  padding: 20px 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s, background-color 0.3s;

  &:hover {
    background-color: #000;
    color: #f0f0f0;
  }
`
