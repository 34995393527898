import React from 'react'
import { useTranslation } from 'react-i18next'

import facebook from '../../assets/media/social/facebook.svg'
import instagram from '../../assets/media/social/instagram.svg'
import linkedin from '../../assets/media/social/linkedin.svg'
import telegram from '../../assets/media/social/telegram.svg'
import upwork from '../../assets/media/social/upwork.svg'
import { Icon } from '../Icon/Icon'
import {
  Address,
  AddressBlock,
  AddressContainer,
  AddressTitle,
  Container,
  Email,
  SocialIconsContainer,
  SubTitle,
  Title,
} from './ContactsMain.styles'

export const ContactsMain: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('contacts.contact')}</Title>
      <SubTitle>{t('contacts.writeToUs')}</SubTitle>
      <Email href='mailto:info@everest-media.ru'>info@everest-media.ru</Email>
      <AddressContainer>
        <AddressBlock>
          <AddressTitle>{t('contacts.moscow')}</AddressTitle>
          <Address>{t('contacts.moscowAddress')}</Address>
        </AddressBlock>
        <AddressBlock>
          <AddressTitle>{t('contacts.tambov')}</AddressTitle>
          <Address>{t('contacts.tambovAddress')}</Address>
        </AddressBlock>
      </AddressContainer>
      <SocialIconsContainer>
        <Icon src={telegram} alt='telegram' />
        <Icon src={linkedin} alt='linkedin' />
        <Icon src={upwork} alt='upwork' />
        <Icon src={facebook} alt='facebook' />
        <Icon src={instagram} alt='instagram' />
      </SocialIconsContainer>
    </Container>
  )
}
