import React from 'react'
import { Outlet } from 'react-router'
import styled from 'styled-components'

import { DiscussButton } from '../components/DiscussButton/DiscussButton'
import { Footer } from '../components/Footer/Footer'
import { Header } from '../components/Header/Header'
import { ScrollToTop } from '../components/ScrollToTop/ScrollToTop'

const Container = styled.div`
  margin: 0 100px;

  @media (max-width: 768px) {
    margin: 0 80px;
  }

  @media (max-width: 480px) {
    margin: 0 20px;
  }
  @media (max-width: 280px) {
    margin: 0 10px;
  }
`

export const Layout: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <DiscussButton />
      <Container>
        <Header />
        <Outlet />
      </Container>
      <Footer />
    </>
  )
}
