import styled from 'styled-components'

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  gap: 20px;
  margin-top: 100px;
  padding: 0 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 15px;
  }
`

export const Card = styled.div`
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: filter 0.5s ease-in-out;

  &:hover img {
    filter: blur(5px);
  }

  &:hover span {
    transform: translateY(0);
    opacity: 1;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
  transition: filter 0.3s ease-in-out;

  @media (max-width: 768px) {
    height: 400px;
  }

  @media (max-width: 480px) {
    height: 250px;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`

export const Box = styled.div``

export const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 1.5em;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export const Tag = styled.span`
  background: #f0f0f0;
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.875em;

  @media (max-width: 768px) {
    padding: 3px 8px;
    font-size: 0.75em;
  }
`

export const Arrow = styled.span`
  display: inline-block;
  font-size: 2em;
  margin: 10px 10px 0 0;
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
`
