import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { JobForm } from '../../components/JobForm/JobForm'

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 200px;
  font-family: Arial, sans-serif;
  color: #333;
`

const Level = styled.div`
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid rgba(19, 21, 29, 0.16);
  width: 70px;
  text-align: center;
  margin-bottom: 60px;
`

const Title = styled.h1`
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 50px;
`

const Subtitle = styled.p`
  font-size: 20px;
  color: #666;
  margin-bottom: 30px;
`

const SectionTitle = styled.h2`
  font-size: 50px;
  margin: 50px 0;
  line-height: 58px;
`

const Text = styled.p`
  font-size: 24px;
  line-height: 1.5;
`

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`

const ListItem = styled.li`
  font-size: 22px;
  display: flex;
  align-items: center;

  &::before {
    content: '•';
    font-size: 35px;
    color: #000;
    margin-right: 10px;
    line-height: 1.5;
  }
`

const SectionText = styled.div`
  font-size: 22px;
  margin-bottom: 25px;
`

const Highlight = styled(SectionText)`
  font-weight: 600;
`

export const UIDesignerMiddle: React.FC = () => {
  const { t } = useTranslation()

  const getArrayTranslation = (key: string): string[] => {
    const translation = t(key, { returnObjects: true })

    return Array.isArray(translation) ? translation : []
  }

  const responsibilities = getArrayTranslation('jobs.responsibilities')
  const requirements = getArrayTranslation('jobs.requirements')
  const pluses = getArrayTranslation('jobs.plus')
  const approaches = getArrayTranslation('jobs.approaches')
  const processes = getArrayTranslation('jobs.process')

  return (
    <Container>
      <Level>Senior</Level>
      <Title>{t('jobs.titleUIDesigner')}</Title>
      <Subtitle>{t('jobs.remote')}</Subtitle>
      <Text>{t('jobs.intro')}</Text>

      <SectionTitle>{t('jobs.responsibilitiesTitle')}</SectionTitle>
      <List>
        {responsibilities.map(item => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </List>

      <SectionTitle>{t('jobs.requirementsTitle')}</SectionTitle>
      <List>
        {requirements.map(item => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </List>

      <SectionTitle>{t('jobs.plusTitle')}</SectionTitle>
      <List>
        {pluses.map(item => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </List>

      <SectionTitle>{t('jobs.approachesTitle')}</SectionTitle>
      <div>
        {approaches.map(item => (
          <SectionText key={item}>{item}</SectionText>
        ))}
        <Highlight>{t('jobs.approachesHighlight')}</Highlight>
        <SectionText>{t('jobs.approachesText')}</SectionText>
      </div>

      <SectionTitle>{t('jobs.processTitle')}</SectionTitle>
      <SectionText>{t('jobs.processText')}</SectionText>
      <List>
        {processes.map(item => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </List>

      <SectionTitle>{t('jobs.qualityTitle')}</SectionTitle>
      <SectionText>{t('jobs.qualityText')}</SectionText>

      <SectionTitle>{t('jobs.nextStepTitle')}</SectionTitle>
      <SectionText>{t('jobs.nextStepText')}</SectionText>
      <Highlight>{t('jobs.waitingForResponse')}</Highlight>

      <JobForm />
    </Container>
  )
}
