import styled, { css } from 'styled-components'

import { BREAK_POINTS, COLORS } from '../../constants/theme'

export const IconWrapper = styled.div<{ theme: 'dark' | 'light' }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  transition: background-color 0.3s, color 0.3s;
  margin: 10px;
  padding: 5px;

  ${props =>
    props.theme === 'dark'
      ? css`
          background-color: ${COLORS.dark};
          & > img {
            filter: invert(100%);
          }
          &:hover {
            background-color: ${COLORS.light};
            & > img {
              filter: invert(0%);
            }
          }
        `
      : css`
          background-color: ${COLORS.light};
          & > img {
            filter: invert(0%);
          }
          &:hover {
            background-color: ${COLORS.dark};
            & > img {
              filter: invert(100%);
            }
          }
        `}

  @media (max-width: ${BREAK_POINTS.desktop}) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    width: 44px;
    height: 44px;
  }

  @media (max-width: ${BREAK_POINTS.mobile}) {
    width: 30px;
    height: 30px;
  }
`

export const IconImage = styled.img`
  width: 30px;
  height: 30px;
  transition: filter 0.3s;

  @media (max-width: ${BREAK_POINTS.desktop}) {
    width: 30px !important;
    height: 30px !important;
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    width: 25px !important;
    height: 25px !important;
  }

  @media (max-width: ${BREAK_POINTS.mobile}) {
    width: 20px !important;
    height: 20px !important;
  }
`
