import React from 'react'

import logo1 from '../../assets/media/partners/geely.png'
import logo3 from '../../assets/media/partners/mastercard.png'
import logo2 from '../../assets/media/partners/mts.png'
import logo4 from '../../assets/media/partners/rainbow.png'
import logo5 from '../../assets/media/partners/smart.png'
import logo6 from '../../assets/media/partners/spotify.png'
import logo7 from '../../assets/media/partners/tymbark.png'
import logo8 from '../../assets/media/partners/uol.png'
import {
  LogoContainer,
  SliderTrack,
  SliderWrapper,
  StyledImage,
} from './LogoSlider.styles'

const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8]

export const LogoSlider: React.FC = () => (
  <SliderWrapper>
    <SliderTrack>
      {logos.concat(logos).map((logo, index) => (
        <LogoContainer key={`logo-${index + 1}`}>
          <StyledImage src={logo} alt={`Logo ${index + 1}`} />
        </LogoContainer>
      ))}
    </SliderTrack>
  </SliderWrapper>
)
