import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  ApplyButton,
  ArrowButton,
  Badge,
  Container,
  Description,
  JobDetails,
  JobItem,
  JobList,
  JobTitle,
  Remote,
  Title,
} from './JobBlock.styles'

export const JobBlock: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('careers.waitingCareers')}</Title>
      <Description>{t('careers.waitingCareersDescription')}</Description>
      <JobList>
        <Link to='/careers/ux-ui-desinger-middle'>
          <JobItem>
            <JobDetails>
              <Badge>MIDDLE</Badge>
              <JobTitle>{t('careers.uxUiDesigner')}</JobTitle>
            </JobDetails>
            <Remote>{t('careers.remote')}</Remote>
            <ArrowButton href='/details' className='arrow-button' />
          </JobItem>
        </Link>
        <Link to='/careers/soft-engineer-senior'>
          <JobItem>
            <JobDetails>
              <Badge>SENIOR</Badge>
              <JobTitle>{t('careers.softwareEngineer')}</JobTitle>
            </JobDetails>
            <Remote>{t('careers.remote')}</Remote>
            <ArrowButton href='/details' className='arrow-button' />
          </JobItem>
        </Link>
      </JobList>
      <Link to='careers'>
        <ApplyButton>{t('careers.joinTeam')}</ApplyButton>
      </Link>
    </Container>
  )
}
