import styled, { css } from 'styled-components'

export const SelectContainer = styled.div<{ theme: 'light' | 'dark' }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100px;
  height: auto;

  ${({ theme }) =>
    theme === 'light'
      ? css`
          color: #000;
        `
      : css`
          color: #fff;
        `}
`

export const SelectLabel = styled.div<{ theme: 'light' | 'dark' }>`
  padding: 10px 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => (theme === 'light' ? '#fff' : '#333')};
  //border: 1px solid ${({ theme }) => (theme === 'light' ? '#ddd' : '#222')};
  cursor: pointer;
  z-index: 1;
  border-radius: 7px;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid
      ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
    position: absolute;
    right: 10px;
    top: calc(50% - 2.5px);
    pointer-events: none;
    z-index: 3;
  }

  img {
    width: 30px;
    height: 30px;
  }
`

export const OptionsContainer = styled.div<{
  isActive: boolean
  theme: 'light' | 'dark'
}>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  position: absolute;
  bottom: 60px;
  width: 100%;
  background: ${({ theme }) => (theme === 'light' ? '#fff' : '#333')};
  //border: 1px solid ${({ theme }) => (theme === 'light' ? '#ddd' : '#222')};
  z-index: 2;
`

export const Option = styled.label<{ theme: 'light' | 'dark' }>`
  padding: 10px 15px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => (theme === 'light' ? '#fff' : '#333')};
  /* border-top: ${({ theme }) =>
    theme === 'light' ? '#ddd' : '#222'} solid 1px; */
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => (theme === 'light' ? '#eee' : '#666')};
  }

  &.selected {
    background: ${({ theme }) => (theme === 'light' ? '#eee' : '#666')};
  }

  img {
    width: 30px;
    height: 30px;
  }
`

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  left: -99999px;
`
